import { Component, HostListener, ViewChild } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect, MatSelectTrigger } from '@angular/material/select';
import { Router } from '@angular/router';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { dateFormat } from 'src/app/shared/date-format';
import { catchError, throwError, timeout, TimeoutError } from 'rxjs';
import { StatusOption } from '../contracted-plans/contracted-plans.component';
import { EStatus } from 'src/app/shared/enums/status.enum';
import { UserService } from 'src/app/services/user.service';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { HeaderComponent } from '../../components/header/header.component';
import { LinkBackComponent } from '../../components/link-back/link-back.component';
import { NgIf, NgFor, DatePipe } from '@angular/common';
import { MatCard, MatCardContent } from '@angular/material/card';
import { ButtonComponent } from '../../components/buttons/button/button.component';
import { MatFormField, MatLabel, MatSuffix, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { NgxMaskDirective } from 'ngx-mask';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { MatCheckbox } from '@angular/material/checkbox';
import { LoadingSpinnerComponent } from '../../components/loading-spinner/loading-spinner.component';

@Component({
    selector: 'yfs-change-report',
    templateUrl: './change-report.component.html',
    styleUrls: ['./change-report.component.scss'],
    standalone: true,
    imports: [HeaderComponent, LinkBackComponent, NgIf, MatCard, MatCardContent, ButtonComponent, MatFormField, MatLabel, MatInput, FormsModule, ReactiveFormsModule, MatDatepickerInput, NgxMaskDirective, MatDatepickerToggle, MatSuffix, MatDatepicker, MatError, MatRadioGroup, MatRadioButton, MatSelect, MatSelectTrigger, MatCheckbox, NgFor, MatOption, LoadingSpinnerComponent, DatePipe]
})
export class ChangeReportComponent {
  public previousScreen: string = '';
  @ViewChild('mySel1') select1: MatSelect | undefined;
  @ViewChild('mySel2') select2: MatSelect | undefined;
  @ViewChild('mySel3') select3: MatSelect | undefined;
  @ViewChild('mySel4') select4: MatSelect | undefined;
  public currentDate = new Date();
  public yesterdayDate = new Date();
  public isLoading = false;
  public dataInicialLabel: Date | undefined = this.yesterdayDate;
  public dataInicial = new FormControl('', [dateFormat.dateValidator, Validators.required]);
  public dataFinalLabel: Date | undefined = this.currentDate;
  public dataFinal = new FormControl('', [dateFormat.dateValidator, Validators.required]);
  public currentFlow: 'parameters' | 'results' | 'nofiles' = 'parameters';
  public dataInicialError = '';
  public dataFinalError = '';
  public maxDateInicial = new Date();
  public maxDateFinal = new Date();
  public canalOriginacao: 'Concessionaria' | 'E-commerce' | 'Todos' = 'Concessionaria'
  public mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public canalRelacionamento: FormControl = new FormControl('');
  public checkedAllRelacionamento = false;
  public interteminateRelacionamento = false;
  public relacionamentoList: any;
  public contrato = new FormControl('');
  public empresas: FormControl = new FormControl('');
  public checkedAllEmpresas = false;
  public interteminateEmpresas = false;
  public empresasList: any;
  public statusOrigem: FormControl = new FormControl('');
  public checkedAllStatusOrigem = false;
  public interteminateStatusOrigem = false;
  public statusDestino: FormControl = new FormControl('');
  public checkedAllStatusDestino = false;
  public interteminateStatusDestino = false;
  public statusOptions: StatusOption[];
  public formatoArquivo = 'pdf';
  public isDisabledMode = false;
  public listItens: any;
  public isFullWidth = window.innerWidth < 1279;

  constructor(private router: Router, private apiService: ApiHttpService, private userService: UserService) {
    this.yesterdayDate.setDate(this.yesterdayDate.getDate() - 1);
    this.maxDateInicial.setDate(this.currentDate.getDate());
    this.maxDateFinal.setDate(this.yesterdayDate.getDate() + 90);
    this.dataFinal.setValue(this.formatDate(this.dataFinalLabel as any))
    this.getAllConcessionariasAndPartner();
    this.getJustConcessionarias();
    this.statusOptions = [
      {
        label: 'Em Aberto',
        value: EStatus.EM_ABERTO
      },
      {
        label: 'Estorno finalizado',
        value: EStatus.ESTORNO_FINALIZADO
      },
      {
        label: 'Estorno em processo',
        value: EStatus.ESTORNO_EM_PROCESSO
      },
      {
        label: 'Elegível',
        value: EStatus.ELEGIVEL
      },
      {
        label: 'Ativo',
        value: EStatus.ATIVO
      },
      {
        label: 'Finalizado',
        value: EStatus.FINALIZADO_CDC
      },
      {
        label: 'Inelegível',
        value: EStatus.INELEGIVEL
      },
      {
        label: 'Cancelado',
        value: EStatus.CANCELADO
      },
      {
        label: 'Inativo',
        value: EStatus.INATIVO
      },
      {
        label: 'Inatividade finalizada',
        value: EStatus.FINALIZADO_INATIVO
      },
      {
        label: 'Desistência em andamento',
        value: EStatus.DESISTENCIA_EM_PROCESSO
      },
      {
        label: 'Desistência finalizado',
        value: EStatus.DESISTECIA_FINALIZADO
      },
      {
        label: 'Aguardando assinatura do termo de adesão',
        value: EStatus.ASSINATURA_PENDENTE_TERMO_ADESAO
      },
      {
        label: 'Aguardando assinatura do termo de cancelamento',
        value: EStatus.ASSINATURA_PENDENTE_TERMO_CANCELAMENTO
      },
      {
        label: 'Liberação de valores CDC',
        value: EStatus.LIBERACAO_VALORES_CDC_EM_PROCESSO
      },
      {
        label: 'Liberação de valores QUITADO ELEGÍVEL',
        value: EStatus.LIBERACAO_VALORES_QUITADO_ELEGIVEL_EM_PROCESSO
      },
      {
        label: 'Liberação de valores QUITADO INELEGÍVEL',
        value: EStatus.LIBERACAO_VALORES_QUITADO_INELEGIVEL_EM_PROCESSO
      },
      {
        label: 'Liberação de valores finalizado',
        value: EStatus.LIBERACAO_VALORES_FINALIZADO
      }
    ];
    this.statusOrigem.setValue(this.statusOptions.map((item: any) => item.value));
    this.statusDestino.setValue(this.statusOptions.map((item: any) => item.value));
    setTimeout(() => {
      this.checkHandleStatusDestino();
      this.checkHandleStatusOrigem();
    }, 200);
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras?.state as { previousScreen: string };
    this.previousScreen = state?.previousScreen || '';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isFullWidth = event.target.innerWidth < 1279;
  }

  getBack() {
    if (this.previousScreen === 'parameters') {
      this.router.navigate(['parameters']);
    } else {
      this.router.navigate(['area']);
    }
  }

  getSelectedLabelStatus(control: FormControl): string {
    const selectedValues = control.value;
    if (selectedValues.length === this.statusOptions.length) {
      return 'Todos';
    } else {
      const selectedLabels = this.statusOptions
        .filter(option => selectedValues.includes(option.value))
        .map(option => option.label);
      return selectedLabels.join(', ');
    }
  }

  getSelectedLabelEmpresas(): string {
    if (!this.empresasList) return ''
    const selectedValues = this.empresas.value;
    if (selectedValues.length === this.empresasList.length) {
      return 'Todos';
    } else {
      const selectedLabels = this.empresasList
        .filter((option: { idConcessionaria: any; }) => selectedValues.includes(option.idConcessionaria))
        .map((option: { razaoSocial: any; }) => option.razaoSocial);
      return selectedLabels.join(', ');
    }
  }

  getSelectedLabelCanalRelacionamento(): string {
    if (!this.relacionamentoList) return ''
    const selectedValues = this.canalRelacionamento.value;
    if (selectedValues.length === this.relacionamentoList.length) {
      return 'Todos';
    } else {
      const selectedLabels = this.relacionamentoList
        .filter((option: { idConcessionaria: any; }) => selectedValues.includes(option.idConcessionaria))
        .map((option: { razaoSocial: any; }) => option.razaoSocial);
      return selectedLabels.join(', ');
    }
  }

  isEnabledButton() {
    if (this.contrato.value && this.contrato.value?.length > 0) return true;
    else if (this.dataInicial.valid && this.dataFinal.valid) return true;
    return false;
  }

  handleInputNumeroContrato() {
    if (this.contrato.value && this.contrato.value?.length > 0) {
      this.dataInicial.disable();
      this.dataFinal.disable();
      this.empresas.disable();
      this.canalRelacionamento.disable();
      this.isDisabledMode = true;
    } else {
      this.dataInicial.enable();
      this.dataFinal.enable();
      this.empresas.enable();
      this.canalRelacionamento.enable();
      this.isDisabledMode = false;
      this.validateDataInicial()
      this.validateDataFinal()
    }
  }

  handleCanalOriginacaoChange() {
    if (this.canalOriginacao == 'E-commerce' || this.canalOriginacao == 'Todos') {
      this.empresas.disable()
      this.empresas.setValue([])
      this.checkHandleEmpresas()
    } else this.empresas.enable()
  }

  handleStatusChange(origem: boolean) {
    if (origem && this.statusOrigem.value.length !== 0 && this.statusOrigem.value.length < this.statusOptions.length) this.statusDestino.disable();
    else this.statusDestino.enable();
    if (!origem && this.statusDestino.value.length !== 0 && this.statusDestino.value.length < this.statusOptions.length) this.statusOrigem.disable();
    else this.statusOrigem.enable()
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  getDateByString(dateString: string) {
    const [day, month, year] = dateString.split('/');
    return new Date(Number(year), Number(month) - 1, Number(day));
  }

  onInputChangeInicial(isCalendar: boolean) {
    if (this.dataInicial.value?.length === 0) {
      this.dataInicialLabel = undefined;
    }
    if (this.dataInicial && this.dataInicial.value?.replace(/[^a-zA-Z0-9]/g, '').length === 8) {
      const dataInicial2 = this.dataInicial.value ?
        this.getDateByString(this.dataInicial.value) : null;
      if (!isCalendar) {
        this.dataInicialLabel = dataInicial2 || undefined
        this.validateDataInicial();
      }
    }
  }

  onInputChangeFinal(isCalendar: boolean) {
    if (isCalendar) {
      this.dataFinal.setValue(this.formatDate(this.dataFinalLabel as any))
    }

    if (this.dataFinal.value?.length === 0) {
      this.dataFinalLabel = undefined;
    }
    if (this.dataFinal && this.dataFinal.value?.replace(/[^a-zA-Z0-9]/g, '').length === 8) {
      const dataFinal2 = this.dataFinal.value ?
        this.getDateByString(this.dataFinal.value) : null;
      if (!isCalendar) {
        this.dataFinalLabel = dataFinal2 || undefined
      }
    }
    this.validateDataFinal()
  }

  validateDataInicial() {
    const dataInicialLabelConvert = new Date(this.dataInicialLabel || '');
    if (dataInicialLabelConvert > this.currentDate) {
      this.dataInicial.setErrors({});
      this.dataInicialError = 'A data inicial não pode ser uma data futura.'
    } else {
      this.dataInicialError = '';
    }
    this.validateDataFinal()
  }

  updateMaxFinalDate() {
    if (!this.dataInicialLabel) return false;
    const ninetyDaysFromNow = new Date(this.dataInicialLabel.getFullYear(), this.dataInicialLabel.getMonth(), this.dataInicialLabel.getDate() + 90);
    this.maxDateFinal = ninetyDaysFromNow;

    return ninetyDaysFromNow;
  }

  isDateGreaterThan90DaysFromNow(date: Date): boolean {
    if (!this.dataInicialLabel) return false;
    const ninetyDaysFromNow = this.updateMaxFinalDate()
    return date <= ninetyDaysFromNow;
  }

  validateDataFinal() {
    const isDataInicialValid = this.dataInicial.value && this.dataInicial.value.replace(/[/_]/g, "").length == 8;
    const isDataFinalValid = this.dataFinal.value && this.dataFinal.value.replace(/[/_]/g, "").length == 8;
    const dataInicio = isDataInicialValid ? new Date(this.getDateByString(this.dataInicial.value || '')) : null;
    const dataFim = isDataFinalValid ? new Date(this.getDateByString(this.dataFinal.value || '')) : null;

    if (dataInicio && dataFim && dataInicio > dataFim) {
      this.dataFinalError = 'A data final não pode ser anterior a data inicial.'
      this.dataFinal.setErrors({ "incorrect": true })
    } else if (dataFim && !this.isDateGreaterThan90DaysFromNow(dataFim)) {
      this.dataFinalError = 'A data final não pode passar dos 90 dias.';
      this.dataFinal.setErrors({ "incorrect": true })
    } else {
      this.dataFinalError = '';
      const errors = { ...this.dataFinal.errors };
      delete errors['incorrect'];
      this.dataFinal.setErrors(Object.keys(errors).length !== 0 ? errors : null);
    }
    this.dataFinal.markAllAsTouched()
    this.updateMaxFinalDate()
  }

  selectAllRelacionamento() {
    if (!this.checkedAllRelacionamento) {
      this.select2?.options.forEach((item: MatOption) => {
        item.select();
      });
    } else {
      this.select2?.options.forEach((item: MatOption) => {
        item.deselect();
      });
    }

    this.interteminateRelacionamento = false;
    this.checkedAllRelacionamento = !this.checkedAllRelacionamento
  }

  checkHandleRelacionamento() {
    const optionFound = this.select2?.options.filter(
      (item: MatOption) => item.selected
    );
    if (optionFound?.length === 0) {
      this.checkedAllRelacionamento = false;
    }

    if (optionFound?.length === this.relacionamentoList?.length) {
      this.checkedAllRelacionamento = true;
    }

    if (optionFound && optionFound?.length > 0 && optionFound?.length < this.relacionamentoList?.length) {
      this.interteminateRelacionamento = true;
    } else {
      this.interteminateRelacionamento = false;
    }
  }

  selectAllEmpresas() {
    if (!this.checkedAllEmpresas) {
      this.select1?.options.forEach((item: MatOption) => {
        item.select();
      });
    } else {
      this.select1?.options.forEach((item: MatOption) => {
        item.deselect();
      });
    }

    this.interteminateEmpresas = false;
    this.checkedAllEmpresas = !this.checkedAllEmpresas
  }

  checkHandleEmpresas() {
    const optionFound = this.select1?.options?.filter(
      (item: MatOption) => item.selected
    );
    if (optionFound?.length === 0) {
      this.checkedAllEmpresas = false;
    }

    if (optionFound?.length === this.empresasList?.length) {
      this.checkedAllEmpresas = true;
    }

    if (optionFound && optionFound?.length > 0 && optionFound?.length < this.empresasList?.length) {
      this.interteminateEmpresas = true;
    } else {
      this.interteminateEmpresas = false;
    }
  }

  selectAllStatusOrigem() {
    if (!this.checkedAllStatusOrigem) {
      this.select3?.options.forEach((item: MatOption) => {
        item.select();
      });
    } else {
      this.select3?.options.forEach((item: MatOption) => {
        item.deselect();
      });
    }

    this.interteminateStatusOrigem = false;
    this.checkedAllStatusOrigem = !this.checkedAllStatusOrigem;
    this.handleStatusChange(true);
  }

  checkHandleStatusOrigem() {
    this.handleStatusChange(true)
    const optionFound = this.select3?.options.filter(
      (item: MatOption) => item.selected
    );
    if (optionFound?.length === 0) {
      this.checkedAllStatusOrigem = false;
    }

    if (optionFound?.length === this.statusOptions.length) {
      this.checkedAllStatusOrigem = true;
    }

    if (optionFound && optionFound?.length > 0 && optionFound?.length < this.statusOptions.length) {
      this.interteminateStatusOrigem = true;
    } else {
      this.interteminateStatusOrigem = false;
    }
  }

  selectAllStatusDestino() {
    if (!this.checkedAllStatusDestino) {
      this.select4?.options.forEach((item: MatOption) => {
        item.select();
      });
    } else {
      this.select4?.options.forEach((item: MatOption) => {
        item.deselect();
      });
    }

    this.interteminateStatusDestino = false;
    this.checkedAllStatusDestino = !this.checkedAllStatusDestino;
    this.handleStatusChange(false)
  }

  checkHandleStatusDestino() {
    this.handleStatusChange(false)
    const optionFound = this.select4?.options.filter(
      (item: MatOption) => item.selected
    );
    if (optionFound?.length === 0) {
      this.checkedAllStatusDestino = false;
    }

    if (optionFound?.length === this.statusOptions.length) {
      this.checkedAllStatusDestino = true;
    }

    if (optionFound && optionFound?.length > 0 && optionFound?.length < this.statusOptions.length) {
      this.interteminateStatusDestino = true;
    } else {
      this.interteminateStatusDestino = false;
    }
  }

  orderbyList(a: any, b: any) {
    if (a.razaoSocial < b.razaoSocial) {
        return -1;
    }
    if (a.razaoSocial > b.razaoSocial) {
        return 1;
    }
    return 0;
  }

  getAllConcessionariasAndPartner() {
    this.isLoading = true;
    this.apiService.getConcessionarias(true).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          this.empresasList = result;
          this.empresasList.sort(this.orderbyList);
          this.empresas.setValue(this.empresasList.map((item: any) => item.idConcessionaria));
          setTimeout(() => {
            this.checkHandleEmpresas();
          }, 200)
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
        },
      });
  }

  converteDataToISO(data: string): string {
    const partes = data.split('/');
    const dataISO = partes[2] + '-' + partes[1].padStart(2, '0') + '-' + partes[0].padStart(2, '0');
    return dataISO;
  }

  search = () => {
    this.isLoading = true

    let canalOriginacaoValue = undefined;
    if (this.canalOriginacao == 'Concessionaria') canalOriginacaoValue = 1;
    if (this.canalOriginacao == 'E-commerce') canalOriginacaoValue = 0;

    const apiObservable = this.contrato.value && this.contrato.value?.length > 0
    ? this.apiService.getChangeReport('', '', undefined, undefined,
      this. statusOrigem.value?.length == this.statusOptions.length ? undefined : this.statusOrigem.value,
      this. statusDestino.value?.length == this.statusOptions.length ? undefined : this.statusDestino.value,
      undefined,
      this.contrato.value)
    : this.apiService.getChangeReport(
      this.converteDataToISO(this.dataInicial.value || ''),
      this.converteDataToISO(this.dataFinal.value || ''),
      this.empresas.value?.length == this.empresasList?.length ? undefined : this.empresas.value || undefined,
      this.canalRelacionamento.value?.length == this.relacionamentoList?.length ? undefined : this.canalRelacionamento.value || undefined,
      this. statusOrigem.value?.length == this.statusOptions.length ? undefined : this.statusOrigem.value,
      this. statusDestino.value?.length == this.statusOptions.length ? undefined : this.statusDestino.value,
      canalOriginacaoValue,
      ''
    );

    apiObservable.pipe(
      timeout(300000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
        }
        return throwError(() => error)
      })
    )
      .subscribe({
        next: result => {
          this.isLoading = false;
          if (result.status == 200 && result.body?.relatorio) {
            this.listItens = result.body?.relatorio;
            this.currentFlow = 'results';
          } else {
            this.currentFlow = 'nofiles';
          }
        },
        error: error => {
          this.isLoading = false;
          console.log(error)
        }
      })
  }

  getJustConcessionarias() {
    this.isLoading = true;
    this.apiService.getConcessionaria(undefined, [1], 1, true).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          this.relacionamentoList = result;
          this.relacionamentoList.sort(this.orderbyList)
          this.canalRelacionamento.setValue(this.relacionamentoList.map((item: any) => item.idConcessionaria));
          setTimeout(() => {
            this.checkHandleRelacionamento();
          }, 200)
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
        },
      });
  }

  handleGenerateReport() {
    if (this.formatoArquivo == 'pdf') {
      this.generatePDF();
    } else {
      this.generateExcel();
    }
  }

  generatePDF(): void {
    const userName = this.userService.getCurrentUser().Nome;
    const doc = new jsPDF('l', 'mm', 'a4');
    const data = this.prepareDataForPDF(this.listItens);
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleString();
    const temEmpresas = this.empresas.value && this.empresas.value?.length > 0;

    doc.setFontSize(10);
    doc.setFont('Helvetica', 'normal');
    var splitTitle = doc.splitTextToSize(`Gerado em: ${formattedDate} Usuário: ${userName}`, 280);
    doc.text(splitTitle, 5, 15);

    doc.addImage('/assets/img/logo_yamaha.png', 'PNG', 10, 20, 120, 20);

    doc.setFontSize(16);
    doc.setFont('Helvetica', 'bold');
    const pageWidth = doc.internal.pageSize.getWidth();
    const title = 'Relatório de Mudança de Status';
    const titleX = (pageWidth - doc.getTextWidth(title)) / 2;
    doc.text(title, titleX, 50);

    doc.setFontSize(10);
    doc.setFont('Helvetica', 'normal');

    const colX = 5;
    const valueX = 60;

    doc.text('Data Inicial', colX, 60);
    doc.text(this.dataInicial.value || '', valueX, 60);
    doc.text('Data Final', colX, 65);
    doc.text(this.dataFinal.value || '', valueX, 65);
    doc.text('Canal de Originação', colX, 70);
    doc.text(this.canalOriginacao, valueX, 70);

    if (temEmpresas) {
      doc.text('Empresas', colX, 75);
      doc.text(
        this.empresas.value?.length == 0
          || this.empresas.value?.length == this.empresasList.length ? 'Todos' : 'Apenas selecionados',
        valueX, 75
      );
    }

    doc.text('Canal de Relacionamento', colX, temEmpresas ? 80 : 75);
    doc.text(
      this.canalRelacionamento.value?.length == 0
        || this.canalRelacionamento.value?.length == this.relacionamentoList.length ? 'Todos' : 'Apenas selecionados',
      valueX, temEmpresas ? 80 : 75
    );
    doc.text('Status Origem', colX, temEmpresas ? 85 : 80);
    doc.text(
      this.statusOrigem.value?.length == 0
        || this.statusOrigem.value?.length == this.statusOptions.length ? 'Todos' : 'Apenas selecionados',
      valueX, temEmpresas ? 85 : 80
    );
    doc.text('Status Final', colX, temEmpresas ? 90 : 85);
    doc.text(
      this.statusDestino.value?.length == 0
        || this.statusDestino.value?.length == this.statusOptions.length ? 'Todos' : 'Apenas selecionados',
      valueX, temEmpresas ? 90 : 85
    );

    autoTable(doc, {
      margin: { left: 5, right: 5 },
      head: [['Numero do contrato', 'Data do contrato', 'Canal de Originação', 'Canal de Relacionamento', 'Status Origem', 'Status Destino', 'Data Alteração', 'Data Pagamento']],
      body: data,
      startY: this.contrato.value ? 95 : (temEmpresas ? 100 : 95),
      styles: {
        lineColor: [255, 255, 255],
        fontSize: 7,
      },
      headStyles: {
        fillColor: [52, 58, 64],
        textColor: [255, 255, 255]
      },
      bodyStyles: {
        fillColor: [255, 255, 255]
      },
      alternateRowStyles: {
        fillColor: [240, 240, 240]
      },
      showHead: "firstPage",
      theme: 'plain'
    });

    doc.save('Relatório de Mudança de status.pdf');
  }

  prepareDataForPDF(data: any): any[] {
    if (!data) {
      console.error('Dados inválidos para geração de PDF.');
      return [];
    }

    return data.map((element: any) => {
      return [
        element.numeroContrato || '',
        element.dataContrato ? this.formatDate(element.dataContrato) : '',
        element.canalOriginacao || '',
        element.canalRelacionamento || '',
        element.statusOrigem ? this.formatStatus(element.statusOrigem) : '',
        element.statusFinal ? this.formatStatus(element.statusFinal) : '',
        element.dataAlteracao ? this.formatDate(element.dataAlteracao) : '',
        element.dataPagamento ? this.formatDate(element.dataPagamento) : '',
      ];
    });
  }

  formatStatus(status: string): string {
    const statusMap: { [key: string]: string } = {
      'Em_Aberto': 'Em Aberto',
      'Estorno_Finalizado': 'Estorno finalizado',
      'Estorno_Em_Andamento': 'Estorno em processo',
      'Elegivel': 'Elegível',
      'Ativo': 'Ativo',
      'Finalizado_CDC': 'Finalizado',
      'Inelegivel': 'Inelegível',
      'Cancelado': 'Cancelado',
      'Inativo': 'Inativo',
      'Finalizado_Inativo': 'Inatividade finalizada',
      'Desistencia_Em_Processo': 'Desistência em andamento',
      'Desistencia_Finalizado': 'Desistência finalizado',
      'Pendente_Assinatura_Termo_Adesao': 'Aguardando assinatura do termo de adesão',
      'Pendente_Assinatura_Termo_Prorrogacao': 'Aguardando assinatura do termo de prorrogação',
      'AtivoProrrogado': 'Ativo prorrogado',
      'Pendente_Assinatura_Termo_Cancelamento': 'Aguardando assinatura do termo de cancelamento',
      'Liberacao_Valores_CDC_Em_Processo': 'Liberação de valores CDC',
      'Liberacao_Valores_Quitado_Elegivel_Em_Processo': 'Liberação de valores QUITADO ELEGÍVEL',
      'Liberacao_Valores_Quitado_Inelegivel_Em_Processo': 'Liberação de valores QUITADO INELEGÍVEL',
      'Liberacao_Valores_Finalizado': 'Liberação de valores finalizado'
    };

    return statusMap[status] || status;
  }

  generateExcel(): void {
    const preparedData = this.prepareDataForExcel(this.listItens);
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(preparedData as any);
    const columnWidths = [
      { wch: 20 },
      { wch: 15 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
    ];

    ws['!cols'] = columnWidths;

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Relatório de Mudança de status');

    XLSX.writeFile(wb, 'Relatório de Mudança de status.xlsx');
  }

  prepareDataForExcel(data: any) {
    const userName = this.userService.getCurrentUser()?.Nome || '';
    if (!data) {
      console.error('Dados inválidos para geração de Excel.');
      return [];
    }

    const preparedData = [[]];
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleString();
    const temEmpresas = this.empresas.value && this.empresas.value?.length > 0;

    preparedData.push([`Relatório de Mudança de Status - Gerado em: ${formattedDate} Usuário: ${userName}`] as any);
    preparedData.push([]);
    if (this.contrato.value) {
      preparedData.push(['Número do Contrato', this.contrato.value || ''] as any);
    } else {
      preparedData.push(['Data Inicial', this.dataInicial.value || ''] as any);
      preparedData.push(['Data Final', this.dataFinal.value || ''] as any);
      preparedData.push(['Canal de Originação', this.canalOriginacao] as any);
      if (temEmpresas) {
        preparedData.push([
          'Empresas',
          this.empresas.value?.length == 0
            || this.empresas.value?.length == this.empresasList.length ?
            'Todos' : 'Apenas selecionados',
        ] as any)
      }
      preparedData.push([
        'Canal de Relacionamento',
        this.canalRelacionamento.value?.length == 0
          || this.canalRelacionamento.value?.length == this.relacionamentoList.length ?
          'Todos' : 'Apenas selecionados',
      ] as any);
    }
    preparedData.push([
      'Status Origem',
      this.statusOrigem.value?.length == 0
          || this.statusOrigem.value?.length == this.statusOptions.length ? 'Todos' : 'Apenas selecionados'
    ] as any);
    preparedData.push([
      'Status Final',
      this.statusDestino.value?.length == 0
          || this.statusDestino.value?.length == this.statusOptions.length ? 'Todos' : 'Apenas selecionados'
    ] as any);
    preparedData.push([]);
    preparedData.push([]);

    const headerTitle = ['Numero do contrato', 'Data do contrato', 'Canal de Originação', 'Canal de Relacionamento', 'Status Origem', 'Status Destino', 'Data Alteração', 'Data Pagamento'];
    preparedData.push(headerTitle as any);

    data.forEach((element: any) => {
      preparedData.push([
        element.numeroContrato || '',
        element.dataContrato ? this.formatDate(element.dataContrato) : '',
        element.canalOriginacao || '',
        element.canalRelacionamento || '',
        element.statusOrigem || '',
        element.statusFinal || '',
        element.dataAlteracao ? this.formatDate(element.dataAlteracao) : '',
        element.dataPagamento ? this.formatDate(element.dataPagamento) : '',
      ] as any);
    });

    return preparedData;
  }


}
